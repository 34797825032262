// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'constants/urls.scss';
@import 'constants/colors.scss';
@import 'constants/media.queries.scss';
//@import 'node_modules/codemirror/theme/lucario.css';

@font-face {
    font-family: 'Roboto';
    src: url($fontFamilyRoboto);
}
@font-face {
    font-family: 'RobotoMedium';
    src: url($fontFamilyRobotoMedium);
}
@font-face {
    font-family: 'RobotoBold';
    src: url($fontFamilyRobotoBold);
}
@font-face {
    font-family: 'RobotoItalic';
    src: url($fontFamilyRobotoItalic);
}
@font-face {
    font-family: 'InterRegular';
    src: url($fontFamilyInterRegular);
}
@font-face {
    font-family: 'InterMedium';
    src: url($fontFamilyInterMedium);
}
@font-face {
    font-family: 'InterBold';
    src: url($fontFamilyInterBold);
}
@font-face {
    font-family: 'InterSemiBold';
    src: url($fontFamilyInterSemiBold);
}
@font-face {
    font-family: 'InterExtraBold';
    src: url($fontFamilyInterExtraBold);
}
@font-face {
    font-family: 'InterLight';
    src: url($fontFamilyInterLight);
}

:root {
    --circle-size: 80%;
    --blending: hard-light;
    --base-font-size: 16px;
    --range-thumb-color: #0AC9BD;
    --tooltip-range-color: #E3E8EC;
}

* {
    box-sizing: border-box; // total width and height of an element include the content, padding, and border
}

html {
    font-size: 10px; /* 1rem = 10px */ /* 1.6rem = 16px */

    @media (max-width: $mobileL) {
        font-size: 8.75px; /* 1rem = 8.75px */ /* 1.6rem = 14px */
    }

    @media (max-width: $mobileS) {
        font-size: 7.5px; /* 1rem = 7.5px */ /* 1.6rem = 12px */
    }
}

html, body {
    margin: 0;
    padding: 0;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04em;
    word-wrap: break-word;
    height: 100%;
}

h1, h2, h3, h4, p {
    margin-block: 0;
}

body {
    font-family: 'InterRegular', sans-serif;
    font-size: 1.6rem; /* =16px */
}

button {
    border: none;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.7s ease;
}

input, textarea {
    border: none;
    resize: none;
    padding: 0;
    cursor: text;
}

textarea {
    white-space: pre-wrap;
}

input[type="radio"], input[type="checkbox"] {
    cursor: pointer;
}

input[type="radio"]:not(:disabled) + label,
input[type="checkbox"]:not(:disabled) + label {
    cursor: pointer;
}

input[type="radio"]:hover:not(:disabled),
input[type="checkbox"]:hover:not(:disabled){
    cursor: pointer;
}

input:disabled + label {
    opacity: .5;
}

input:focus, textarea:focus, button:focus {
    outline: none;
}

button:disabled, input:disabled, textarea:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

ul {
    padding: 0;
    margin: 0;
}

// Dialog style
.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 3.2rem;
}

.mat-mdc-dialog-content {
    max-height: 90vh !important;
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: 0 36px 0 36px !important;
}

.dialog-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 38px 36px 24px 36px;
}

.dialog-footer {
    padding: 24px 36px 36px 36px !important;
}

.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 30px;
    border-radius: 50%;
    background-color: $addButton;
}

.close-button:hover {
    background-color: $addButtonHover;
}

.blurBackground {
    background-color: rgba(59, 71, 84, 0.50);
    backdrop-filter: blur(3px);
}

// Scrollbar style
// Chrome
*:not(pre, .word-container, textarea)::-webkit-scrollbar {
    width: 8px;
}

*:not(pre, .word-container, textarea)::-webkit-scrollbar-track {
    border-radius: 2rem;
    margin: 2px;
}

*:not(pre, .word-container, textarea)::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    border: 10px solid transparent;
    background-color: $dialogInputText;
    cursor: pointer;
}

textarea::-webkit-scrollbar, ul::-webkit-scrollbar {
    width: 20px;
}

textarea::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
    background-color: $dialogInputText;
    background-clip: content-box;
    border: 8px solid transparent;
    border-radius: 2rem;
    min-height: 50px;
    cursor: pointer;
}

.parent-container {
    display: contents;
}

.CodeMirror {
    height: 100% !important;
}

.cdk-global-overlay-wrapper {
    overflow: auto;
}

.mat-mdc-dialog-container {
    padding-top: 50px;
    padding-bottom: 50px;

    .mat-mdc-dialog-content {
        overflow: unset !important;
        max-height: unset !important;
    }
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: var(--mat-dialog-with-actions-content-padding, 20px 24px);
}

// svg components
app-close, app-arrow, app-delete, app-file-upload, app-go-to-assistant, app-copy, app-correct, app-plus, app-filter,
app-assistant-icon, app-knowledge, app-action, app-edit, app-link, app-menu, app-select-arrow {
    display: contents;
}
