@import 'urls.scss';
@import 'colors.scss';
@import 'media.queries.scss';

// Loader
.swal2-loader {
    display: none !important;
}

// Popup error
.swal2-actions {
    margin: 0 !important;
}

.swal2-confirm, .swal2-cancel {
    border-radius: 2.4rem;
    background: $primaryBlue;
    padding: 1.2rem 3.2rem;
    font-size: 1.4rem;
    font-family: 'InterMedium', sans-serif;
    color: $primaryWhite;
}

.swal2-confirm:hover, .swal2-cancel:hover {
    background: $companyLogo;
}

.swal2-popup {
    //width: 0%;
    max-width: 550px;
    width: 100%;
    height: 100%;
    max-height: 400px;
    border-radius: 3.2rem;

    @media (max-width: $tabletXl) {
        width: 60%;
    }
}

.swal2-title, .swal2-html-container {
    color: $inputText !important;
    margin: 0 !important;
}

.swal2-title {
    font-size: 3.2rem !important;
    font-family: 'InterBold', sans-serif;
    padding: 1.5rem !important;

    @media (max-width: $tabletXl) {
        font-size: 2.5rem !important;
    }
}

.swal2-html-container {
    font-size: 1.6rem !important;
    font-family: 'InterMedium', sans-serif;
    padding: 2rem !important;

    @media (max-width: $tabletXl) {
        font-size: 1.4rem !important;
    }
}

.swal2-image {
    margin: 4rem auto 1rem auto;
    width: auto;
    height: 70%;

    @media (max-width: $tablet) {
        height: 60%;
    }
}

// Delete, Changes popup
.delete-popup, .changes-popup {
    max-width: 55rem;
    width: 100%;
    height: 100%;
    max-height: 37rem;
    border-radius: 3.2rem;
    padding: 3rem !important;
}

.delete-title, .changes-title {
    font-size: 3.2rem !important;
    font-family: 'InterBold', sans-serif;
    padding: 0 !important;
    color: $loadingText !important;
    margin: 0 !important;
}

.delete-html-container, .changes-html-container {
    font-size: 1.6rem !important;
    font-family: 'InterMedium', sans-serif;
    padding: 0 !important;
    color: $loadingText !important;
}

.delete-image, .changes-image {
    height: auto;
    margin: auto auto 3rem auto;
    width: 12rem;
}

.delete-actions, .changes-actions {
    margin: 0 !important;

    .swal2-confirm, .swal2-cancel {
        border-radius: 2.4rem;
        padding: 1.2rem 3.2rem;
        font-size: 1.4rem;
        font-family: 'InterMedium', sans-serif;
        transition: background-color 0.7s ease;

        &hover {
            cursor: pointer;
        }
    }

    .swal2-cancel, .swal2-cancel:hover {
        background: transparent !important;
        color: $accountName;
        border: 1px solid $accountName;
    }
}

.delete-actions {
    .swal2-confirm {
        background: $deletePopupButton;
        color: $primaryWhite;
    }

    .swal2-confirm:hover {
        background: $deletePopupButtonHover;
    }
}

.changes-actions {
    .swal2-confirm {
        background: $primaryBlue;
        color: $primaryWhite;
    }

    .swal2-confirm:hover {
        background: $companyLogo;
    }
}

// TODO - make some solution to use directly app-loader component
// Loader
@keyframes resizeLoadBar {
    0%, 80%, 100% {
        height: 3em;
    }
    40% {
        height: 6em;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    right: 0;
}
.loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 9em;
    height: 4em;
    position: relative;
}
.loading-text {
    color: $loadingText;
    font-family: 'InterBold', sans-serif;
    font-size: 1.6rem;
    margin-top: 4rem;
    text-align: center;
}
.loader span {
    display: block;
    background: $primaryBlue;
    width: 1.5em;
    height: 4em;
    animation: resizeLoadBar 1s infinite ease-in-out;
    position: relative;
    border-radius: 2rem;
}
.loader span:nth-child(1) {
    animation-delay: 0s;
}
.loader span:nth-child(2) {
    animation-delay: -0.3s;
}
.loader span:nth-child(3) {
    animation-delay: -0.6s;
}
.loader span:nth-child(4) {
    animation-delay: -0.9s;
}
.loader span:nth-child(5) {
    animation-delay: -1.2s;
}
