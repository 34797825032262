// primary
$primaryBlue: rgba(85, 152, 254, 1);
$primaryWhite: rgba(255, 255, 255, 1);
$welcomeText: rgba(17, 26, 42, 1);
$companyLogo: rgba(0, 88, 221, 1);
$backgroundLanding: rgba(1, 115, 229, 1);
$loginShadow: rgba(7, 59, 112, 0.35);
$loginText: rgba(205, 205, 206, 1);
$inputBackground: rgba(239, 239, 239, 1);
$inputText: rgba(95, 99, 104, 1);
$inputPlaceholder: rgba(141, 141, 141, 1);
$buttonHover: rgba(10, 201, 189, 1);
$lineHeader: rgba(233, 236, 239, 1);
$accountName: rgba(101, 122, 144, 1);
$screenTitle: rgba(118, 249, 202, 1);
$screenDescription: rgba(251, 251, 251, 1);
$landingContainer: rgba(243, 245, 246, 1);
$addButton: rgba(227, 232, 236, 1);
$addButtonHover: rgba(169, 184, 198, 1);
$dialogInputText: rgba(129, 148, 166, 1);
$scroll: rgba(217, 217, 217, 1);
$scrollTrack: rgba(198, 208, 218, 1);
$paginationBorderElement: rgba(220, 220, 222, 1);
$dropzoneProgress: rgba(120, 226, 143, 1);
$feedbackModalBackground: rgba(243, 245, 246, 1);
$modalBoxShadow: rgba(0, 0, 0, 0.56);
$closeButtonBackground: rgba(218, 222, 226, 1);
$deletePopupButton: rgba(224, 61, 61, 1);
$deletePopupButtonHover: rgba(201, 10, 10, 1);

    // not found page
$errorDescription: rgba(95, 99, 104, 1);

// loading page
$blueGradientT: rgba(18, 78, 245, 0.8);
$blueGradient: rgba(18, 78, 245, 0);
$greenGradientT: rgba(33, 204, 112, 0.8);
$greenGradient: rgba(33, 204, 112, 0);
$lightBlueGradientT: rgba(75, 172, 233, 0.8);
$lightBlueGradient: rgba(75, 172, 233, 0);
$lightGreenGradientT: rgba(107, 206, 150, 0.8);
$lightGreenGradient: rgba(107, 206, 150, 0);
$loadingText: rgba(49, 64, 90, 1);
$characterShadow: rgba(33, 95, 188, 0.10);
$analyticsTextButton: rgba(235, 243, 255, 1);
