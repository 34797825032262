@import 'urls.scss';
@import 'colors.scss';
@import 'media.queries.scss';

.dropzone {
    color: $dialogInputText;
    border: 2px dashed $primaryBlue;
    border-radius: 2rem;
    padding: 3rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.dropzone .dz-message {
    margin: 0;
}

.dz-button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.dz-button p {
    color: $dialogInputText;
    text-align: center;
    font-family: "InterRegular", sans-serif;
    font-size: 1rem;
    line-height: 150%;
}

.dz-button img {
    width: 45px;
}

.dropzone .dz-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    cursor: pointer;
}

.dropzone .dz-preview .dz-remove {
    display: inline-block !important;
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: 1000;
    padding: 5px;
    border: 4px solid $screenDescription;
    border-radius: 50%;
    color: $screenDescription;
    background-color: $addButton !important;
    transition: background 0.7s ease;
    content: url($removeButton);
}

.dropzone .dz-preview .dz-remove:hover {
    background-color: $addButtonHover !important;
}

.dz-file-preview .dz-image {
    background: $scrollTrack !important;
}

.dz-progress {
    display: none;

    border: none !important;
    background: $screenDescription !important;
    height: 12px !important;
    bottom: 16% !important;
    top: auto !important;
}

.dz-progress .dz-upload {
    background: $dropzoneProgress !important;
}
